import { useState } from "react";
import "./product.images.css";
import ImageModal from "../../../../components/ImageModal/ImageModal";
const ProductImages = ({ images, publicFolder }) => {
  const [imgIndex, setImgIndex] = useState(0);
  const [openLightbox, setOpenLightbox] = useState(false);
  const closeModal = () => {
    setOpenLightbox(false);
  };

  return (
    <div>
      {openLightbox && (
        <ImageModal
          show_nav={images.length > 1 ? true : false}
          firstIMG={imgIndex === 0}
          lastIMG={imgIndex === images.length - 1}
          prevImg={() => setImgIndex(imgIndex - 1)}
          nextIMG={() => setImgIndex(imgIndex + 1)}
          image={images[imgIndex]}
          closeModal={closeModal}
          publicFolder={publicFolder}
        />
      )}
      <div className="col-3">
        <img
          className="product_page_large_img shadow"
          src={publicFolder + images[imgIndex]}
          onClick={() => setOpenLightbox(true)}
        />
      </div>
      {images.length > 1 ? (
        <div className="col-3 product_image_list">
          {images.map((item, index) => (
            <img
              src={publicFolder + item}
              className="product_page_small_img shadow"
              alt=""
              onClick={() => setImgIndex(index)}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default ProductImages;
