import axios from "axios";
import config from "../config/config";
// const api_url = "http://localhost:5040/api/v1/public";
const api_url = config.API_URL + "/api/v1/public";

const fetchData = async (url) => {
  try {
    const response = await axios.get(api_url + url);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error);
    }
  }
};

const postData = async (url, data) => {
  try {
    const response = await axios.post(api_url + url, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error);
    }
  }
};

const publicAPIServices = {
  fetchData,
  postData,
};

export default publicAPIServices;
