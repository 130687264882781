import { useState, useEffect } from "react";
import price_units from "../data/price.units";

export const useCheckScreenSize = () => {
  const [isSmallerThan768px, setIsSmallerThan768px] = useState(
    window.innerWidth < 768
  );

  useEffect(() => {
    function handleResize() {
      setIsSmallerThan768px(window.innerWidth < 768);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isSmallerThan768px;
};

export const getPriceUnitValue = (lang, unitKey) => {
  const unit = price_units.find((item) => item.key === unitKey);
  if (!unit) return null;
  return lang === "hu" ? unit.hu : unit.at;
};

export const useFormattedNumber = (number) => {
  const [formattedNumber, setFormattedNumber] = useState("");

  useEffect(() => {
    const formattedValue = new Intl.NumberFormat("en-US").format(number);
    setFormattedNumber(formattedValue);
  }, [number]);

  return formattedNumber;
};

// export default useCheckScreenSize;
