import { createSlice } from "@reduxjs/toolkit";
import { about_us_fetchAllData } from "./aboutUsActions";

const initialState = {
  about_us_data: null,
  about_us_loading: false,
  about_us_error: false,
  about_us_errCode: null,
};

const aboutUsSlice = createSlice({
  name: "aboutUs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(about_us_fetchAllData.pending, (state) => {
        state.about_us_loading = true;
        state.about_us_error = null;
      })
      .addCase(about_us_fetchAllData.fulfilled, (state, action) => {
        state.about_us_loading = false;
        state.about_us_error = false;
        state.about_us_data = action.payload;
      })
      .addCase(about_us_fetchAllData.rejected, (state, action) => {
        state.about_us_loading = false;
        state.about_us_error = action.error.message;
      });
  },
});

export default aboutUsSlice.reducer;
