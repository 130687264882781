import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import ProductCard from "../../../../components/ProductsWrapper/ProductCard";
import locale_routes from "../../../../config/locales/locale.routes";

import locale_product_pages from "../../../../config/locales/locale.product.pages";
import { Link } from "react-router-dom";
const ProductCarousel = ({ lang, products, title }) => {
  const locale = locale_product_pages;
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {products && products.length > 0 ? (
        <>
          <h2 className="m-bottom">{title}</h2>
          <div className="col-3" style={{ position: "relative" }}>
            <Carousel responsive={responsive}>
              {products.map((item, index) => (
                <ProductCard
                  title={item[lang].title}
                  price={item.price}
                  image={item.gallery[0]}
                  id={item._id}
                  path={`/${locale_routes.products_url[lang]}/${item[lang].slug}`}
                  key={index}
                  lang={lang}
                />
              ))}
              <div className="homepage_product_carousel_btn">
                <Link to={locale_routes.products_url[lang]} className="shadow">
                  Megnézem az összeset
                </Link>
              </div>
            </Carousel>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ProductCarousel;
