import React from "react";
import config from "../../config/config";
import { Link } from "react-router-dom";
import locale_product_pages from "../../config/locales/locale.product.pages";
import { useFormattedNumber } from "../../utils/hooks";
const ProductCard = ({ title, price, lang, image, id, path, customClass }) => {
  const PF = config.API_URL;
  const locale = locale_product_pages;
  const formattedValue = useFormattedNumber();

  const formatCurrency = (amount) => {
    return amount.toLocaleString("hu-HU", {
      minimumFractionDigits: 0,
    });
  };

  return (
    <>
      <a href={path}>
        <div className={`app__product_card shadow ${customClass}`}>
          <div className="app__product_card_img">
            <img
              src={PF + `/static/images/products/${id}/${image}`}
              alt={title}
              title={title}
            />
          </div>
          <div className="app__product_card_title">
            <h2>{title}</h2>
          </div>
          <div className="app__product_card_price">
            <p>
              {lang === "at"
                ? formatCurrency(price.eur) + " EUR"
                : formatCurrency(price.huf) + " Ft"}
            </p>
          </div>
          <div className="app__product_card_button">
            <a href={path} className="shadow">
              {locale.product_card_btn[lang]}
            </a>
          </div>
        </div>
      </a>
    </>
  );
};

export default ProductCard;
