const locale_homepage = {
  page_title: {
    hu: "Köszöntünk minden kedves látogatót a weboldalunkon!",
    at: "Herzlich willkommen an alle Besucher unserer Website!",
  },
  contacts: {
    hu: "Elérhetőségeink",
    at: "Kontakt",
  },
  favorites: {
    hu: "Kedvenceitek",
    at: "Deine Favoriten",
  },
  latest: {
    hu: "Újdonságok",
    at: "Neue Produkte",
  },
};

export default locale_homepage;
