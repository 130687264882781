import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import locale_routes from "../../config/locales/locale.routes";
import { useNavigate, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { fetchBySlug } from "../../context/productsSlices/productsActions";
import "./product.page.css";
import ProductImages from "./Components/ProductImages/ProductImages";
import ReservationForm from "./Components/ReservationForm/ReservationForm";
import config from "../../config/config";

import { getPriceUnitValue } from "../../utils/hooks";
import CombinedProduct from "./Components/CombinedProduct/CombinedProduct";
import SeparatableProduct from "./Components/SeparatableProduct/SeparatableProduct";
import ShippingInfos from "./Components/ShippingInfos/ShippingInfos";
import locale_product_pages from "../../config/locales/locale.product.pages";
const ProductPage = ({ lang }) => {
  const [index, setIndex] = useState(1);
  const [openForm, setOpenForm] = useState(false);
  const [PF, setPF] = useState(null);

  const [unit, setUnit] = useState(null);
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const { product, loading } = useSelector((state) => state.products);

  const { pathname } = useLocation();
  const local = locale_product_pages;
  const slug = lang === "at" ? pathname.split("/")[3] : pathname.split("/")[2];

  // console.log(slug);

  useEffect(() => {
    if (product && product._id) {
      setPF(config.API_URL + `/static/images/products/${product._id}/`);
    }
    if (product && product.price && product.price.unit) {
      setUnit(getPriceUnitValue(lang, product.price.unit));
    }
  }, [product]);

  useEffect(() => {
    if (!loading && product !== null) {
      redirect(`/${locale_routes.products_url[lang]}/${product.slug}`);
    }
  }, [lang, product, slug]);

  useEffect(() => {
    // console.log(slug);
    if (slug !== undefined && slug !== "luftschlosser" && lang === "hu") {
      const tempSlugHU = pathname.split("/")[2];
      dispatch(fetchBySlug(`/${tempSlugHU}?lang=${lang}`));
    }
    if (slug !== undefined && lang === "at") {
      const tempSlugAT = pathname.split("/")[3];
      dispatch(fetchBySlug(`/${tempSlugAT}?lang=${lang}`));
    }
    scrollToTop();
  }, [lang, pathname]);

  const closeFormModal = () => {
    setOpenForm(false);
  };

  const openFormModal = () => {
    setOpenForm(true);
  };

  const scrollToDiv = () => {
    const element = document.getElementById("page-container");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const formatCurrency = (amount) => {
    return amount.toLocaleString("hu-HU", {
      minimumFractionDigits: 0,
    });
  };

  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <>
      {openForm ? (
        <ReservationForm
          product={product}
          closeFormModal={() => closeFormModal()}
          lang={lang}
        />
      ) : null}
      <div className="app__container fade-in product_page page-container">
        <div className="wrapper">
          {loading && product === null ? (
            <p>Loading</p>
          ) : !loading && product === null ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ textAlign: "center" }}>
                  {local.product_load_fail_text[lang]}
                </p>
                <p>{local.product_load_fail_cta[lang]}</p>
                <button
                  style={{
                    fontSize: "22px",
                    border: "none",
                    borderRadius: "25px",
                    backgroundColor: "var(--bg-primary)",
                    color: "#fff",
                    padding: "6px 20px",
                    cursor: "pointer",
                    maxWidth: "400px",
                  }}
                  onClick={refreshPage}
                >
                  {local.product_load_fail_btn[lang]}
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <h1>{product.title}</h1>
              </div>
              <div className="row">
                <div className="col-2">
                  <ProductImages publicFolder={PF} images={product.gallery} />
                </div>
                <div className="col-2 d-flex-center-col">
                  <div
                    className="product_description"
                    dangerouslySetInnerHTML={{
                      __html: product.description,
                    }}
                  />
                  <div className="product_price">
                    <p className="other_text">{local.rental_text[lang]}</p>
                    <p>
                      {lang === "at"
                        ? formatCurrency(product.price.eur) + " EUR"
                        : formatCurrency(product.price.huf) + " Ft"}{" "}
                      /{" "}
                      {product.price.unit_amount === null ||
                      product.price.unit_amount === 0
                        ? null
                        : product.price.unit_amount}{" "}
                      {unit} {local.brutto_text[lang]}
                    </p>
                    <p className="other_text">
                      {local.rental_text_shipping[lang]}
                    </p>
                  </div>
                  <div className="product_page_button">
                    <button
                      type="button"
                      onClick={() => openFormModal()}
                      className="shadow"
                    >
                      {local.reservation_btn[lang]}
                    </button>
                  </div>
                </div>
              </div>
              {product.parts !== null ? (
                <div className="row">
                  <div className="col-3">
                    <CombinedProduct
                      lang={lang}
                      main_product={product.parts.main_element}
                      part_products={product.parts.part_elements}
                    />
                  </div>
                </div>
              ) : null}
              {product.combined.state && product.combined.parts.length > 0 ? (
                <div className="row">
                  <div className="col-3">
                    <SeparatableProduct
                      lang={lang}
                      part_products={product.combined.parts}
                    />
                  </div>
                </div>
              ) : null}
              <div className="row">
                <ShippingInfos lang={lang} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductPage;
