import { createAsyncThunk } from "@reduxjs/toolkit";
import publicAPIServices from "../../services/APIServices";
const API = "/informations/";
const RFQ_API = "/products/";

export const send_rfq = createAsyncThunk("form/sendRFQ", async (payload) => {
  try {
    const response = await publicAPIServices.postData(
      RFQ_API + "send-rfq",
      payload
    );
    return response;
  } catch (error) {
    throw new Error(error.status);
  }
});

export const send_msg = createAsyncThunk("form/sendMSG", async (payload) => {
  try {
    const response = await publicAPIServices.postData(
      API + "send-message",
      payload
    );
    return response;
  } catch (error) {
    throw new Error(error.status);
  }
});
