import React from "react";
// import "./app.header.css";
import { useDispatch } from "react-redux";
import { setLanguage } from "../../../context/langSlices/langReducers";

import at_flag from "../../../assets/media/AT_flag.png";
import hu_flag from "../../../assets/media/HU_flag.png";

import locale_navitems from "../../../config/locales/local.navitems";
import { Link } from "react-router-dom";
import header_phone_icon from "../../../assets/media/header_phone.png";
import header_email_icon from "../../../assets/media/header_email.png";
// impoo;
const DesktopHeader = ({ lang }) => {
  const dispatch = useDispatch();
  const locale = locale_navitems;
  const handleLanguageChange = (lang) => {
    dispatch(setLanguage(lang));
  };

  return (
    <>
      <div className="app__full_width primary_bg">
        <div className="row ">
          <div className="col-2">
            <p className="app__header_title">{locale.header_text[lang]}</p>
          </div>
          <div className="col-2 d-flex_inline">
            <div
              className="col app__header_text "
              style={{ marginRight: "15px" }}
            >
              <a href="tel:+36202710036" className="app__header_text">
                <img
                  src={header_phone_icon}
                  className="header__icon"
                  alt=""
                  title="BULIService"
                />
                +3620 271 0036
              </a>
            </div>
            <div className="col">
              <a
                href="mailto:info@buliservice2000.com"
                className="app__header_text"
              >
                <img
                  src={header_email_icon}
                  className="header__icon"
                  alt=""
                  title="BULIService"
                />
                info@buliservice2000.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="app__full_width">
        <div className="app__header_hero"></div>
      </div>
      <div className="app__full_width shadow">
        <div className="row d-flex-center" style={{ padding: "5px" }}>
          <div>
            <ul className="app_navitems_desktop">
              <Link to={`${locale.homepage[lang].path}`}>
                <li className="app__navitem">{locale.homepage[lang].title}</li>
              </Link>
              <Link to={`${locale.about_us[lang].path}`}>
                <li className="app__navitem">{locale.about_us[lang].title}</li>
              </Link>
              <Link to={`${locale.air_castles_page[lang].path}`}>
                <li className="app__navitem">
                  {locale.air_castles_page[lang].title}
                </li>
              </Link>
              <Link to={`${locale.gallery_page[lang].path}`}>
                <li className="app__navitem">
                  {locale.gallery_page[lang].title}
                </li>
              </Link>
              <Link to={`${locale.contact_page[lang].path}`}>
                <li className="app__navitem">
                  {locale.contact_page[lang].title}
                </li>
              </Link>
            </ul>
          </div>
          <div
            // className="col-1 d-flex-center"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              marginLeft: "15px",
            }}
          >
            <img
              className="app__header_flag"
              onClick={() => handleLanguageChange("hu")}
              src={hu_flag}
              alt=""
              title="HU flag"
            />
            <img
              className="app__header_flag"
              onClick={() => handleLanguageChange("at")}
              src={at_flag}
              alt=""
              title="AT flag"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DesktopHeader;
