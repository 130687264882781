const locale_contact_page = {
  page_title: {
    hu: "Vedd fel velünk a kapcsolatot!",
    at: "Kontaktieren Sie uns!",
  },
  contacts: {
    hu: "Elérhetőségeink",
    at: "Kontakt",
  },
  name: {
    hu: "Név",
    at: "Name",
  },
  email: {
    hu: "E-mail cím",
    at: "E-Mail Adresse",
  },
  phone: {
    hu: "Telefonszám",
    at: "Rufnummer",
  },
  message: {
    hu: "Miben segíthetünk?",
    at: "Wie können wir helfen?",
  },
  comment: {
    hu: "Megjegyzés",
    at: "Kommentar",
  },
  btn_text: {
    hu: "Elküldöm",
    at: "Senden",
  },
  datepicker_label: {
    hu: "Melyik napra szeretnéd lefoglalni?",
    at: "Für welchen Tag möchten Sie ihn buchen?",
  },
  selected_product: {
    hu: "Választott légvár",
    at: "Ausgewählte Luftschloss",
  },
  success_text_1: {
    hu: "Nagyon szépen köszönjük az érdeklődésed!",
    at: "Vielen Dank für Ihr Interesse!",
  },
  success_text_2: {
    hu: "Munkatársunk hamarosan felveszi veled a kapcsolatot.",
    at: "Wir werden uns in Kürze mit Ihnen in Verbindung setzen.",
  },
  success_btn: {
    hu: "Ablak bezárása",
    at: "Formular schließen",
  },
  error_text_1: {
    hu: "Nem sikerült elküldeni az üzenetet, kérlek próbáld meg újra pár perc múlva vagy hívj minket.",
    at: "Die Nachricht wurde nicht gesendet. Bitte versuchen Sie es in ein paar Minuten erneut oder rufen Sie uns an.",
  },
  error_text_2: {
    hu: "Köszönjük a megértésed és a türelmed!",
    at: "Vielen Dank für Ihr Verständnis und Ihre Geduld!",
  },
};

export default locale_contact_page;
