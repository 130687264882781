const locale_page_errors = {
  page_not_found_text: {
    hu: "Az oldal, amit keresel, olyan elszántan eldugta magát, hogy még én sem találom meg. Vagy talán csak a 404 hibaüzenetes oldalra vágytál?",
    at: "Die Seite, die Sie suchen, versucht so verzweifelt, sich zu verstecken, dass selbst ich sie nicht finden kann. Oder wollten Sie vielleicht nur die Seite mit der 404-Fehlermeldung?",
  },
  page_not_found_cta: {
    hu: "Kérlek, kattints az alábbi gombra, hogy visszatérj a kezdőlapra!",
    at: "Bitte klicken Sie auf die Schaltfläche unten, um zur Startseite zurückzukehren!",
  },
  page_not_found_btn: {
    hu: "Vissza a kezdőlapra",
    at: "Zurück zur Hauptseite",
  },
};

export default locale_page_errors;
