import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLanguage } from "../../../context/langSlices/langReducers";

import at_flag from "../../../assets/media/AT_flag.png";
import hu_flag from "../../../assets/media/HU_flag.png";
import { GiHamburgerMenu } from "react-icons/gi";
import locale_navitems from "../../../config/locales/local.navitems";
import { Link } from "react-router-dom";

// temp
import "./app.mobile.css";
const MobileHeader = ({ lang }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const locale = locale_navitems;
  const handleLanguageChange = (lang) => {
    dispatch(setLanguage(lang));
    setIsOpen(!isOpen);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavClick = () => {
    scrollToTop();
    setIsOpen(false);
  };
  return (
    <>
      <div className="app__full_width primary_bg sticky shadow">
        <div
          className="rows"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <div style={{ maxWidth: "70%" }}>
            <p className="app__header_title">{locale.header_text[lang]}</p>
          </div>
          <div className="menu-icon" onClick={toggleMenu}>
            <GiHamburgerMenu size={"40px"} />
          </div>
        </div>
        <div
          className={`app__hamburger_menu_div ${isOpen ? "opened" : "closed"}`}
        >
          <ul className={`app_navitems_mobile ${isOpen ? "opened" : "closed"}`}>
            <Link
              className="dropdown-item"
              to={`${locale.homepage[lang].path}`}
              onClick={handleNavClick}
            >
              <li className="app__navitem">{locale.homepage[lang].title}</li>
            </Link>
            <Link
              className="dropdown-item"
              to={`${locale.about_us[lang].path}`}
              onClick={handleNavClick}
            >
              <li className="app__navitem">{locale.about_us[lang].title}</li>
            </Link>
            <Link
              className="dropdown-item"
              to={`${locale.air_castles_page[lang].path}`}
              onClick={handleNavClick}
            >
              <li className="app__navitem">
                {locale.air_castles_page[lang].title}
              </li>
            </Link>
            <Link
              className="dropdown-item"
              to={`${locale.gallery_page[lang].path}`}
              onClick={handleNavClick}
            >
              <li className="app__navitem">
                {locale.gallery_page[lang].title}
              </li>
            </Link>
            <Link
              className="dropdown-item"
              to={`${locale.contact_page[lang].path}`}
              onClick={handleNavClick}
            >
              <li className="app__navitem">
                {locale.contact_page[lang].title}
              </li>
            </Link>
          </ul>
          <div
            // className="col-1 d-flex-center"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              marginLeft: "15px",
            }}
          >
            <img
              className="app__header_flag_mobile"
              onClick={() => handleLanguageChange("hu")}
              src={hu_flag}
              alt=""
              title="HU flag"
            />
            <img
              className="app__header_flag_mobile"
              onClick={() => handleLanguageChange("at")}
              src={at_flag}
              alt=""
              title="AT flag"
            />
          </div>
        </div>
      </div>

      {/* menu */}
      {/* <div className="app__full_width shadow">
        <div className="row d-flex-center" style={{ padding: "5px" }}>
          <div>
            <ul className="app_navitems_desktop">
              <Link to={`${locale.about_us[lang].path}`}>
                <li className="app__navitem">{locale.about_us[lang].title}</li>
              </Link>
              <Link to={`${locale.air_castles_page[lang].path}`}>
                <li className="app__navitem">
                  {locale.air_castles_page[lang].title}
                </li>
              </Link>
              <Link to={`${locale.gallery_page[lang].path}`}>
                <li className="app__navitem">
                  {locale.gallery_page[lang].title}
                </li>
              </Link>
              <Link to={`${locale.contact_page[lang].path}`}>
                <li className="app__navitem">
                  {locale.contact_page[lang].title}
                </li>
              </Link>
            </ul>
          </div>
          <div
            // className="col-1 d-flex-center"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              marginLeft: "15px",
            }}
          >
            <img
              className="app__header_flag"
              onClick={() => handleLanguageChange("hu")}
              src={hu_flag}
              alt=""
              title="HU flag"
            />
            <img
              className="app__header_flag"
              onClick={() => handleLanguageChange("at")}
              src={at_flag}
              alt=""
              title="AT flag"
            />
          </div>
        </div>
      </div> */}

      {/* hero */}
      <div className="app__full_width">
        <div className="app__header_hero"></div>
      </div>
    </>
  );
};

export default MobileHeader;
