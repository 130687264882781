import { createSlice } from "@reduxjs/toolkit";
import { fetchAllImages } from "./galleryActions";

const initialState = {
  images: [],
  loading: false,
  error: false,
  errCode: null,
};

const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllImages.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.product = null;
      })
      .addCase(fetchAllImages.fulfilled, (state, action) => {
        state.loading = false;
        state.images = action.payload;
        state.product = null;
      })
      .addCase(fetchAllImages.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errCode = action.error.message;
        state.product = null;
      });
  },
});

export default gallerySlice.reducer;
