const locale_about_us_page = {
  page_title: {
    hu: "Bemutatkozás",
    at: "Über uns",
  },
  introduction: {
    hu: "<p>Vállalkozásunk 2009-ben alakult. Kezdetben főként hang és fénytechnikával, valamint rendezvények dekorálásával foglalkoztunk a légvárak üzemeltetése mellett. Ez az évek során változott, és az utóbbi 4-5 évben a fő profilunk a vidámparki játékok üzemeltetése és bérbeadása lett. Jelenleg is megtalálható még a hang és fénytechnika, de csak kisebb rendezvények esetén tudjuk azt vállalni.</p><br /><p>A vállalkozás célja játékparkunk folyamatos fejlesztése, és olyan játékok vásárlása, ami gyermekek és felnőttek részére egyaránt használható. Igyekszünk minden évben új játokokkal bővíteni a kínálatot ezzel is biztosítva a változatosságot. Légváraink mérete és minősége a „nagyüzemi” használatra van kifejlesztve. Sajnos a piacon sok olyan játék megtalálható már, ami nem arra lett kitalálva, hogy 5-10 gyermek tartózkodhasson benne. Sok esetben csak a kiszállítás során szembesülnek ezzel a megrendelők, ami kellemetlen helyzetet tud teremteni.</p><br /><p>Fontos számunkra a biztonság és a megfelelő higiéniai feltételek biztosítása. Ezért nagy hangsúlyt fektetünk a légvárak műszaki állapotának folyamatos ellenőrzésére, valamint takarítására. A légvárak kiszállítását és beüzemelését minden esetben mi végezzük.</p><br /><p>Bízunk benne, hogy mindenki megtalálja a neki megfelelő játékot, és találkozhatunk egy családi vagy nagyobb rendezvényen 😊</p><br /><p>Kérdés esetén forduljon hozzánk bizalommal!</p><p>Üdvözlettel:</p><p>BULIservice@ Team</p>",
    at: '<p>Unser Unternehmen wurde im Jahr 2009 gegründet. Am Anfang waren wir hauptsächlich in der Ton- und Lichttechnik und der Dekoration von Veranstaltungen tätig, sowie im Betrieb von Hüpfburgen. Dies hat sich im Laufe der Jahre geändert, und in den letzten 4-5 Jahren hat sich unser Hauptprofil auf den Betrieb und die Vermietung von Vergnügungsparks verlagert. Derzeit bieten wir immer noch Beschallung und Beleuchtung an, allerdings nur für kleinere Veranstaltungen.</p><p>Ziel des Unternehmens ist es, unseren Spielzeugpark ständig weiterzuentwickeln und Spielzeug zu kaufen, das von Kindern und Erwachsenen gleichermaßen genutzt werden kann. Jedes Jahr versuchen wir, neue Spielzeuge in unser Sortiment aufzunehmen, um für Abwechslung zu sorgen. Die Größe und Qualität unserer Hüpfburgen sind für den "großen" Einsatz konzipiert. Leider gibt es auf dem Markt viele Spielzeuge, die nicht für 5-10 Kinder geeignet sind. In vielen Fällen werden die Kunden erst bei der Lieferung damit konfrontiert, was zu einer unangenehmen Situation führen kann. </p><p>Für uns ist es wichtig, die Sicherheit und gute Hygienebedingungen zu gewährleisten. Deshalb legen wir großen Wert auf die ständige Überwachung des technischen Zustands der Hüpfburgen und deren Reinigung. Wir liefern und bauen die Hüpfburgen immer selbst auf. </p><p>Wir hoffen, dass jeder das richtige Spielzeug für sich findet und wir Sie bei einer Familien- oder Großveranstaltung begrüßen dürfen 😊.</p><p>Bitte kontaktieren Sie uns, wenn Sie Fragen haben! </p><p>Mit freundlichen Grüßen:<br>BULIservice@ Team</p><p><br /></p>',
  },
  short_intro: {
    hu: "<p>Vállalkozásunk 2009-ben alakult. Kezdetben főként hang és fénytechnikával, valamint rendezvények dekorálásával foglalkoztunk a légvárak üzemeltetése mellett. Ez az évek során változott, és az utóbbi 4-5 évben a fő profilunk a vidámparki játékok üzemeltetése és bérbeadása lett. Jelenleg is megtalálható még a hang és fénytechnika, de csak kisebb rendezvények esetén tudjuk azt vállalni.</p>",
    at: "<p>Unser Unternehmen wurde im Jahr 2009 gegründet. Am Anfang waren wir hauptsächlich in der Ton- und Lichttechnik und der Dekoration von Veranstaltungen tätig, sowie im Betrieb von Hüpfburgen. Dies hat sich im Laufe der Jahre geändert, und in den letzten 4-5 Jahren hat sich unser Hauptprofil auf den Betrieb und die Vermietung von Vergnügungsparks verlagert. Derzeit bieten wir immer noch Beschallung und Beleuchtung an, allerdings nur für kleinere Veranstaltungen.</p>",
  },

  intro_btn: {
    hu: "Tovább olvasom",
    at: "Mehr lesen",
  },
};

export default locale_about_us_page;
