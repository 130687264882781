const locale_routes = {
  homepage_url: {
    hu: "/",
    at: "/at",
  },
  contact_page_url: {
    hu: "kapcsolat",
    at: "at/kontakt",
  },
  products_url: {
    hu: "legvarak",
    at: "at/luftschlosser",
  },
  about_us_url: {
    hu: "rolunk",
    at: "at/uber-uns",
  },
  gallery_url: {
    hu: "galeria",
    at: "at/galerie",
  },
  legvarak_v2_url: {
    hu: "legvarak-v2",
    at: "legvarak-v2",
  },

  privacy_url: {
    hu: "adatvedelem",
    at: "at/datenschutz",
  },
};

export default locale_routes;
