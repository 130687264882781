import React from "react";
import ProductCard from "./ProductCard";
import "./product.wrapper.css";
import locale_routes from "../../config/locales/locale.routes";
const ProductsWrapper = ({ lang, products }) => {
  return (
    <>
      {products && products.length > 0 ? (
        <div className="app__product_wrapper">
          {products.map((item, index) => (
            <ProductCard
              lang={lang}
              key={index}
              title={item[lang].title}
              image={item.gallery[0]}
              price={item.price}
              id={item._id}
              path={`/${locale_routes.products_url[lang]}/${item[lang].slug}`}
            />
          ))}
        </div>
      ) : (
        <p>...</p>
      )}
    </>
  );
};

export default ProductsWrapper;
