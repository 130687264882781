import React from "react";
import "./app.header.css";

import DesktopHeader from "./Desktop/DesktopHeader";
import MobileHeader from "./Mobile/MobileHeader";

import { useCheckScreenSize } from "../../utils/hooks";
const Header = ({ lang }) => {
  const isMobileScreen = useCheckScreenSize();
  return (
    <>
      <header>
        {isMobileScreen ? (
          <MobileHeader lang={lang} />
        ) : (
          <DesktopHeader lang={lang} />
        )}
      </header>
    </>
  );
};

export default Header;
