const locale_privacy = {
  main_text: {
    hu: " Az oldalon cookie-kat használunk, hogy a legjobb felhasználói élményt nyújthassuk.",
    at: " Wir verwenden Cookies auf dieser Website, um Ihnen das beste Nutzererlebnis zu bieten.",
  },
  info_link_text: {
    hu: "További infók",
    at: "Mehr Infos",
  },
  accept_btn: {
    hu: "Elfogadom",
    at: "Deine Akzeptieren",
  },
  latest: {
    hu: "Újdonságok",
    at: "Neue Produkte",
  },
};

export default locale_privacy;
