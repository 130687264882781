import { useEffect } from "react";
import { Link } from "react-router-dom";
import locale_routes from "../../config/locales/locale.routes";
import { useNavigate, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { fetchAllProducts } from "../../context/productsSlices/productsActions";
// import ProductsWrapper from "./Components/ProductsWrapper/ProductsWrapper";
import ProductsWrapper from "../../components/ProductsWrapper/ProductsWrapper";
const AllProductsPage = ({ lang }) => {
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const { allProducts, loading } = useSelector((state) => state.products);

  const { pathname } = useLocation();
  const slug = lang === "at" ? pathname.split("/")[3] : pathname.split("/")[2];

  useEffect(() => {
    // dispatch(fetchBySlug(`/${slug}?lang=${lang}`));
    dispatch(fetchAllProducts());
    scrollToDiv();
  }, [lang, pathname]);

  useEffect(() => {
    if (!loading && allProducts) {
      redirect(`/${locale_routes.products_url[lang]}`);
    }
  }, [lang, allProducts]);

  const scrollToDiv = () => {
    const element = document.getElementById("page-container");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="app__container fade-in" id="page-container">
      <div className="wrapper">
        <div>
          {loading ? (
            <p>Loading</p>
          ) : (
            <ProductsWrapper lang={lang} products={allProducts} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AllProductsPage;
