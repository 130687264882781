import React from "react";
import locale_product_pages from "../../../../config/locales/locale.product.pages";
import shipping_img from "../../../../assets/media/shipping_img.png";
import "./shipping.infos.css";
const ShippingInfos = ({ lang }) => {
  const locale = locale_product_pages;
  return (
    <>
      <div className="col-3" style={{ marginTop: "25px" }}>
        <h2>{locale.shipping_title[lang]}</h2>
      </div>
      <div className="col-2">
        <div
          className=""
          dangerouslySetInnerHTML={{
            __html: locale.shipping_infos[lang],
          }}
        />
      </div>
      <div className="col-2 d-flex-center shipping_image">
        <img src={shipping_img} alt="" title="Szállítási információk" />
      </div>
    </>
  );
};

export default ShippingInfos;
