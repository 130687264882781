import React from "react";
import "./app.footer.css";
import footer_logo from "../../assets/media/footer_logo.png";
import { Link } from "react-router-dom";
import locale_navitems from "../../config/locales/local.navitems";

import footer_email from "../../assets/media/header_email.png";
import footer_phone from "../../assets/media/header_phone.png";
import footer_address from "../../assets/media/address_light.png";
import footer_facebook from "../../assets/media/footer_facebook.png";
const Footer = ({ lang }) => {
  const locale = locale_navitems;

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const map_url =
    "https://www.google.com/maps/place/Pet%C5%91h%C3%A1za,+D%C3%B3zsa+Gy%C3%B6rgy+u.+9,+9443+Magyarorsz%C3%A1g/@47.5980775,16.8961709,17z/data=!3m1!4b1!4m6!3m5!1s0x476c053dd475f1af:0xe3c5e9a5ffa0313d!8m2!3d47.5980739!4d16.8987458!16s%2Fg%2F11h1r8shx6?entry=ttu";

  const facebook_link =
    "https://www.facebook.com/profile.php?id=100063752132654";
  return (
    <div className="app__full_width app__footer app__footer_bg">
      <div className="row " style={{ minHeight: "200px", paddingTop: "15px" }}>
        <div className="col-1 d-flex-center-col">
          <img
            className="app__footer_logo"
            src={footer_logo}
            alt="BULIservice légvárak"
            title="BULIservice logó"
          />
          <div className="footer__social_icon_wrapper">
            <a href={facebook_link} target="_blank">
              {" "}
              <img
                className="footer__social_icon"
                src={footer_facebook}
                alt={""}
                title="Facebook oldal"
              />
            </a>
          </div>
        </div>
        <div className="col-1 d-flex-center">
          <ul className="app__footer_list_items">
            <li className="app__footer_navitem">
              <Link to={`/${locale.air_castles_page[lang].path}`}>
                {locale.air_castles_page[lang].title}
              </Link>
            </li>
            <li className="app__footer_navitem">
              <Link to={`/${locale.about_us[lang].path}`}>
                {locale.about_us[lang].title}
              </Link>
            </li>
            <li className="app__footer_navitem">
              <Link to={`/${locale.gallery_page[lang].path}`}>
                {locale.gallery_page[lang].title}
              </Link>
            </li>
            <li className="app__footer_navitem">
              <Link to={`/${locale.contact_page[lang].path}`}>
                {locale.contact_page[lang].title}
              </Link>
            </li>
            <Link to={`/${locale.privacy_page[lang].path}`}>
              <li className="app__footer_navitem">
                {locale.privacy_page[lang].title}
              </li>
            </Link>
          </ul>
        </div>
        <div className="col-1">
          <h3>Elérhetőségeink:</h3>
          <ul className="app__footer_list_items">
            <li className="app__footer_navitem contact_item">
              <a href="tel:+36202710036">
                {" "}
                <img src={footer_phone} alt="" title="" />
                +3620 271 0036
              </a>
            </li>
            <li className="app__footer_navitem  contact_item">
              <a href="mailto:info@buliservice2000.com">
                {" "}
                <img src={footer_email} alt="" title="" />
                info@buliservice2000.com
              </a>
            </li>
            <li className="app__footer_navitem  contact_item">
              <a href={map_url} target="_blank">
                {" "}
                <img src={footer_address} alt="" title="" />
                9443 Petőháza, Dózsa György u. 9
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-3 d-flex-center">
          <p className="footer_signature">
            BULIservice@ {new Date().getFullYear()} | Készítette:{" "}
            <a
              className="footer_signature"
              href="https://agrandlabs.hu"
              target="_blank"
            >
              Agrand Digital Labs
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
