import { createSlice } from "@reduxjs/toolkit";
import {
  fetchBySlug,
  fetchAllProducts,
  fetchLatest,
  fetchFavorites,
} from "./productsActions";

const initialState = {
  product: null,
  allProducts: [],
  loading: false,
  error: false,
  errCode: null,

  favorite_products: [],
  favorite_products_loading: false,
  favorite_products_error: false,
  favorite_products_errCode: null,

  latest_products: [],
  latest_products_loading: false,
  latest_products_error: false,
  latest_products_errCode: null,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProducts.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.product = null;
      })
      .addCase(fetchAllProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.allProducts = action.payload;
        state.product = null;
      })
      .addCase(fetchAllProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errCode = action.error.message;
        state.product = null;
      })
      .addCase(fetchBySlug.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.product = null;
      })
      .addCase(fetchBySlug.fulfilled, (state, action) => {
        state.product = null;

        state.loading = false;
        state.product = action.payload;
      })
      .addCase(fetchBySlug.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errCode = action.error.message;
        state.product = null;
      })
      .addCase(fetchFavorites.pending, (state) => {
        state.favorite_products_loading = true;
        state.favorite_products_error = false;
        state.product = null;
      })
      .addCase(fetchFavorites.fulfilled, (state, action) => {
        state.favorite_products_loading = false;
        state.favorite_products = action.payload;
        state.product = null;
      })
      .addCase(fetchFavorites.rejected, (state, action) => {
        state.favorite_products_loading = false;
        state.favorite_products_error = true;
        state.favorite_products_errCode = action.error.message;
        state.product = null;
      })

      .addCase(fetchLatest.pending, (state) => {
        state.latest_products_loading = true;
        state.latest_products_error = false;
        state.product = null;
      })
      .addCase(fetchLatest.fulfilled, (state, action) => {
        state.latest_products_loading = false;
        state.latest_products = action.payload;
        state.product = null;
      })
      .addCase(fetchLatest.rejected, (state, action) => {
        state.latest_products_loading = false;
        state.latest_products_error = true;
        state.latest_products_errCode = action.error.message;
        state.product = null;
      });
  },
});

export default productSlice.reducer;
