import { useEffect } from "react";
import "./homepage.css";
import locale_routes from "../../config/locales/locale.routes";
import { useNavigate, useLocation } from "react-router-dom";
import locale_homepage from "../../config/locales/locale.homepage";
import ProductCarousel from "./Components/ProductCarousel/ProductCarousel";

import { useSelector, useDispatch } from "react-redux";
import {
  fetchFavorites,
  fetchLatest,
} from "../../context/productsSlices/productsActions";
import Introduction from "./Components/Introduction/Introduction";
const Homepage = ({ lang }) => {
  const locale = locale_homepage;
  const redirect = useNavigate();
  const dispatch = useDispatch();

  const {
    favorite_products,
    favorite_products_loading,
    latest_products,
    latest_products_loading,
  } = useSelector((state) => state.products);

  useEffect(() => {
    // scrollToDiv();
    scrollToTop();
  }, []);

  useEffect(() => {
    redirect(`${locale_routes.homepage_url[lang]}`);
  }, [lang, redirect]);

  const scrollToDiv = () => {
    const element = document.getElementById("page-container");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    // dispatch(fetchBySlug(`/${slug}?lang=${lang}`));
    dispatch(fetchFavorites());
    dispatch(fetchLatest());
    scrollToTop();
    // scrollToDiv();
  }, [lang]);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="app__container fade-in" id="page-container">
      <div className="wrapper">
        <div className="row m-bottom">
          <div className="col-3">
            <h1>{locale.page_title[lang]}</h1>
          </div>
          {favorite_products_loading ? (
            <p>loading</p>
          ) : (
            <div className="row">
              <div className="col-3">
                <ProductCarousel
                  lang={lang}
                  title={locale.favorites[lang]}
                  products={favorite_products}
                />
              </div>
            </div>
          )}
          <div
            className="row secondary__bg round__corners"
            style={{ padding: "15px" }}
          >
            <Introduction
              lang={lang}
              btn_path={locale_routes.about_us_url[lang]}
            />
          </div>
          {latest_products_loading ? (
            <p>loading</p>
          ) : (
            <div className="row">
              <div className="col-3">
                <ProductCarousel
                  lang={lang}
                  title={locale.latest[lang]}
                  products={latest_products}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Homepage;
