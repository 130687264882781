import { createAsyncThunk } from "@reduxjs/toolkit";
import publicAPIServices from "../../services/APIServices";

const API = "/products/";

export const fetchAllProducts = createAsyncThunk(
  "product/fetchAllProducts",
  async (url) => {
    try {
      const response = await publicAPIServices.fetchData(API + "get-all-data");
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

export const fetchBySlug = createAsyncThunk(
  "product/fetchBySlug",
  async (url) => {
    try {
      const response = await publicAPIServices.fetchData(
        API + "get-data" + url
      );
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

export const fetchFavorites = createAsyncThunk(
  "product/fetchFavorites",
  async (url) => {
    try {
      const response = await publicAPIServices.fetchData(API + "get-favorites");
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

export const fetchLatest = createAsyncThunk(
  "product/fetchLatest",
  async (url) => {
    try {
      const response = await publicAPIServices.fetchData(API + "get-latest");
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);
