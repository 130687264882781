const price_units = [
  {
    key: 1101,
    hu: "Alkalom",
    at: "Gelegenheit",
  },
  {
    key: 1102,
    hu: "Óra",
    at: "Stunde",
  },
  {
    key: 1103,
    hu: "Nap",
    at: "Tag",
  },
];

export default price_units;
