import { useState } from "react";
import locale_contact_page from "../../../../config/locales/locale.contact.page";

import { send_msg } from "../../../../context/formSlice/formActions";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
const ContactForm = ({ lang }) => {
  const locale = locale_contact_page;
  const dispatch = useDispatch();

  const { form_loading } = useSelector((state) => state.form);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSendForm = async (e) => {
    e.preventDefault();
    const res = await dispatch(send_msg(formData));
    if (res.payload === "OK") {
      setSuccess(true);
    } else {
      setError(true);
    }
  };
  return (
    <>
      {form_loading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSendForm}>
          <input
            value={formData.name}
            onChange={handleChange}
            id="name"
            name="name"
            autoComplete="name"
            placeholder={locale.name[lang]}
            required
          />
          <input
            value={formData.phone}
            onChange={handleChange}
            id="phone"
            name="phone"
            autoComplete="phone"
            required
            placeholder={locale.phone[lang]}
          />
          <input
            value={formData.email}
            onChange={handleChange}
            id="email"
            name="email"
            autoComplete="email"
            required
            placeholder={locale.email[lang]}
          />
          <textarea
            value={formData.message}
            onChange={handleChange}
            id="message"
            name="message"
            required
            placeholder={locale.message[lang]}
          />
          {success ? (
            <>
              <p style={{ textAlign: "center", color: "#309627" }}>
                {locale.success_text_1[lang]}
              </p>
              <p style={{ textAlign: "center", color: "#309627" }}>
                {locale.success_text_2[lang]}
              </p>
            </>
          ) : error ? (
            <>
              <p style={{ textAlign: "center", color: "#F13E3E" }}>
                {locale.error_text_1[lang]}
              </p>
              <p style={{ textAlign: "center", color: "#F13E3E" }}>
                {locale.error_text_2[lang]}
              </p>
            </>
          ) : (
            <button type="submit" className="app__form_button">
              {locale.btn_text[lang]}
            </button>
          )}
        </form>
      )}
    </>
  );
};

export default ContactForm;
