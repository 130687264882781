import { useState, useEffect } from "react";
import config from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllImages } from "../../context/gallerySlices/galleryActions";
import ImageModal from "../../components/ImageModal/ImageModal";
import "./gallery.page.css";
import locale_routes from "../../config/locales/locale.routes";
import { useNavigate, useLocation } from "react-router-dom";

const GalleryPage = ({ lang }) => {
  const [imgIndex, setImgIndex] = useState(0);
  const [openLightbox, setOpenLightbox] = useState(false);
  const { images, loading } = useSelector((state) => state.gallery);
  const dispatch = useDispatch();
  const redirect = useNavigate();

  const publicFolder = config.API_URL + "/static/images/gallery/";
  useEffect(() => {
    scrollToDiv();
  }, []);

  useEffect(() => {
    dispatch(fetchAllImages());
  }, [lang]);

  useEffect(() => {
    redirect(`/${locale_routes.gallery_url[lang]}`);
  }, [lang, redirect]);

  const closeModal = () => {
    setOpenLightbox(false);
  };

  const openImage = (index) => {
    setImgIndex(index);
    setOpenLightbox(true);
  };

  const scrollToDiv = () => {
    const element = document.getElementById("page-container");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      {openLightbox && (
        <ImageModal
          show_nav={images.length > 1 ? true : false}
          firstIMG={imgIndex === 0}
          lastIMG={imgIndex === images.length - 1}
          prevImg={() => setImgIndex(imgIndex - 1)}
          nextIMG={() => setImgIndex(imgIndex + 1)}
          image={images[imgIndex]}
          closeModal={closeModal}
          publicFolder={publicFolder}
        />
      )}
      <div className="app__container fade-in" id="page-container">
        <div className="wrapper">
          <div className="row m-bottom">
            <div className="col-3">
              <h1>{lang === "hu" ? "Galéria" : "Galerie"}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-3 gallery__wrap">
              {!loading && images && images.length > 0 ? (
                images.map((item, index) => (
                  <img
                    onClick={() => openImage(index)}
                    className="gallery__page_img shadow"
                    src={publicFolder + item}
                  />
                ))
              ) : (
                <p>Loading</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryPage;
