import { useEffect } from "react";
import locale_contact_page from "../../config/locales/locale.contact.page";
import locale_routes from "../../config/locales/locale.routes";
import { useNavigate, useLocation } from "react-router-dom";

import ContactForm from "./Components/ContactForm/ContactForm";
import "./contact.page.css";

import phone_icon from "../../assets/media/phone_dark.png";
import email_icon from "../../assets/media/email_dark.png";
import address_icon from "../../assets/media/address_dark.png";
const ContactPage = ({ lang }) => {
  const locale = locale_contact_page;
  const redirect = useNavigate();

  useEffect(() => {
    scrollToDiv();
  }, []);

  useEffect(() => {
    redirect(`/${locale_routes.contact_page_url[lang]}`);
  }, [lang, redirect]);

  const scrollToDiv = () => {
    const element = document.getElementById("page-container");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="app__container fade-in" id="page-container">
      <div className="wrapper">
        <div className="row m-bottom">
          <div className="col-3 d-flex-center">
            <h1>{locale.page_title[lang]}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <ContactForm lang={lang} />
          </div>
          <div className="col-2 d-flex-center-col">
            <div>
              <h2 style={{ marginBottom: "15px" }}>{locale.contacts[lang]}:</h2>
              <ul className="contact_page_contacts">
                <li>
                  <a href="https://google.com">
                    <img
                      className="app__contact_page_icon"
                      src={phone_icon}
                      alt=""
                    />
                    +3620 271 0036
                  </a>
                </li>
                <li>
                  <a href="https://google.com">
                    <img
                      className="app__contact_page_icon"
                      src={email_icon}
                      alt=""
                    />{" "}
                    info@buliservice2000.com
                  </a>
                </li>
                <li>
                  <a href="https://google.com">
                    <img
                      className="app__contact_page_icon"
                      src={address_icon}
                      alt=""
                    />{" "}
                    9443 Petőháza, Dózsa György u. 9
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
