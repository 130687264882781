import React from "react";
import { useNavigate } from "react-router-dom";
import locale_routes from "../../config/locales/locale.routes";
import locale_page_errors from "../../config/locales/locale.page.errors";
const PageNotFound = ({ lang }) => {
  const navigate = useNavigate();
  const locale = locale_page_errors;
  return (
    <>
      <div className="app__container fade-in product_page page-container">
        <div className="wrapper">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ textAlign: "center" }}>
              {locale.page_not_found_text[lang]}
            </p>
            <p>{locale.page_not_found_cta[lang]}</p>
            <button
              style={{
                fontSize: "22px",
                border: "none",
                borderRadius: "25px",
                backgroundColor: "var(--bg-primary)",
                color: "#fff",
                padding: "6px 20px",
                cursor: "pointer",
                maxWidth: "450px",
              }}
              onClick={() => navigate(`${locale_routes.homepage_url[lang]}`)}
            >
              {locale.page_not_found_btn[lang]}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
