import React from "react";
import "./separatable.product.css";
import ProductCard from "../../../../components/ProductsWrapper/ProductCard";
import locale_routes from "../../../../config/locales/locale.routes";
import locale_product_pages from "../../../../config/locales/locale.product.pages";
const SeparatableProduct = ({ lang, main_product, part_products }) => {
  const locale = locale_product_pages;
  return (
    <>
      <h2 style={{ marginTop: "25px" }}>{locale.separated_parts_text[lang]}</h2>
      <div className="separated_products_wrapper">
        {part_products.map((product, index) => (
          <>
            <ProductCard
              customClass="separated_product_card"
              title={product[lang].title}
              price={product.price}
              lang={lang}
              image={product.gallery[0]}
              id={product._id}
              path={`/${locale_routes.products_url[lang]}/${product[lang].slug}`}
            />
          </>
        ))}
      </div>
    </>
  );
};

export default SeparatableProduct;
