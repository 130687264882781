import React from "react";
import ProductCard from "../../../../components/ProductsWrapper/ProductCard";
import "./combined.product.css";
import locale_routes from "../../../../config/locales/locale.routes";
import locale_product_pages from "../../../../config/locales/locale.product.pages";
const CombinedProduct = ({ lang, main_product, part_products }) => {
  const locale = locale_product_pages;
  return (
    <>
      <h2 style={{ marginTop: "25px" }}>{locale.combined_text[lang]}</h2>
      <div className="combined_products_wrapper">
        {part_products.map((product, index) => (
          <>
            <ProductCard
              customClass="combined_product_card"
              title={product[lang].title}
              price={product.price}
              lang={lang}
              image={product.gallery[0]}
              id={product._id}
              path={`/${locale_routes.products_url[lang]}/${product[lang].slug}`}
            />
            {index === 0 ? <p className="comined_product_symbols">+</p> : null}
          </>
        ))}
        <p className="comined_product_symbols">=</p>
        <ProductCard
          customClass="combined_product_card"
          title={main_product[lang].title}
          price={main_product.price}
          lang={lang}
          image={main_product.gallery[0]}
          id={main_product._id}
          path={`/${locale_routes.products_url[lang]}/${main_product[lang].slug}`}
        />
      </div>
    </>
  );
};
export default CombinedProduct;
