import { useEffect } from "react";
import "./about.us.page.css";
import locale_about_us_page from "../../config/locales/local.about.us";
import locale_routes from "../../config/locales/locale.routes";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { about_us_fetchAllData } from "../../context/aboutUsSlices/aboutUsActions";
import about_us_img from "../../assets/media/about_us_img.jpg";
import Loader from "../../components/Loader/Loader";
const AboutUsPage = ({ lang }) => {
  const locale = locale_about_us_page;
  const redirect = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    scrollToDiv();
  }, []);

  const { about_us_data, about_us_loading, about_us_error, about_us_errCode } =
    useSelector((state) => state.aboutUs);

  useEffect(() => {
    dispatch(about_us_fetchAllData());
  }, [dispatch]);

  useEffect(() => {
    redirect(`/${locale_routes.about_us_url[lang]}`);
  }, [lang, redirect]);

  const scrollToDiv = () => {
    const element = document.getElementById("page-container");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {about_us_loading ? (
        <Loader />
      ) : !about_us_loading && about_us_data ? (
        <div
          className="app__container fade-in product_page"
          id="page-container"
        >
          <div className="wrapper">
            <div className="row">
              {/* <h1>{locale.page_title[lang]}</h1> */}
              <h1>{about_us_data[lang].page_title}</h1>
            </div>
            <div className="row">
              <div className="col-3">
                {" "}
                <div
                  className="about_us_intro"
                  dangerouslySetInnerHTML={{
                    __html: about_us_data[lang].introduction,
                  }}
                />
                <div className="col-3">
                  <img
                    className="about_us_page_image shadow"
                    src={about_us_img}
                    alt="Légvár bérlés"
                    title="Légvár bérlés"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AboutUsPage;
