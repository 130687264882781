import { useState } from "react";
import "./reservation.form.css";
import { IoCloseCircle } from "react-icons/io5";
import locale_contact_page from "../../../../config/locales/locale.contact.page";
import { send_rfq } from "../../../../context/formSlice/formActions";
import { useSelector, useDispatch } from "react-redux";
const ReservationForm = ({ lang, product, closeFormModal }) => {
  const locale = locale_contact_page;
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    toy: product && product.title ? product.title : "",
    date: null,
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const { form_loading } = useSelector((state) => state.form);
  // Min date
  const twoDaysFromTomorrow = new Date();
  twoDaysFromTomorrow.setDate(twoDaysFromTomorrow.getDate() + 2); // today + 2 days
  const minDate = twoDaysFromTomorrow.toISOString().split("T")[0];

  const handleSendForm = async (e) => {
    e.preventDefault();
    const res = await dispatch(send_rfq(formData));
    if (res.payload === "OK") {
      setSuccess(true);
    } else {
      setError(true);
    }
  };
  return (
    <>
      <div className="reservation_modal fade-in">
        <div className="reservation_form_container">
          <div className="reservation_close_form_button">
            <IoCloseCircle onClick={() => closeFormModal()} />
          </div>
          <form className="reservation_form" onSubmit={handleSendForm}>
            <input
              value={formData.name}
              onChange={handleChange}
              id="name"
              name="name"
              autoComplete="name"
              placeholder={locale.name[lang]}
              required
            />
            <input
              value={formData.phone}
              onChange={handleChange}
              id="phone"
              name="phone"
              autoComplete="phone"
              required
              placeholder={locale.phone[lang]}
            />
            <input
              value={formData.email}
              onChange={handleChange}
              id="email"
              name="email"
              autoComplete="email"
              required
              placeholder={locale.email[lang]}
            />

            <label htmlFor="selected_product">
              {locale.selected_product[lang]}
            </label>
            <input
              name="selected_product"
              id="selected_product"
              value={product.title}
              disabled
            />
            <label htmlFor="selected_date">
              {locale.datepicker_label[lang]}
            </label>
            <input
              type="date"
              name="date"
              id="date"
              value={formData.date}
              onChange={handleChange}
              min={minDate}
              required
            />
            <textarea
              value={formData.message}
              onChange={handleChange}
              id="message"
              name="message"
              placeholder={locale.comment[lang]}
            />
            {success ? (
              <>
                <p style={{ textAlign: "center", color: "#309627" }}>
                  {locale.success_text_1[lang]}
                </p>
                <p style={{ textAlign: "center", color: "#309627" }}>
                  {locale.success_text_2[lang]}
                </p>
                <button
                  style={{
                    backgroundColor: "#f05821",
                    border: "none",
                    borderRadius: "25px",
                    color: "#fff",
                    fontSize: "22px",
                    padding: "16px 24px",
                    cursor: "pointer",
                  }}
                  onClick={closeFormModal}
                >
                  {locale.success_btn[lang]}
                </button>
              </>
            ) : error ? (
              <>
                <p style={{ textAlign: "center", color: "#F13E3E" }}>
                  {locale.error_text_1[lang]}
                </p>
                <p style={{ textAlign: "center", color: "#F13E3E" }}>
                  {locale.error_text_2[lang]}
                </p>
              </>
            ) : (
              <button type="submit" className="app__form_button">
                {locale.btn_text[lang]}
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default ReservationForm;
