import { useEffect } from "react";
import "./app.privacy.page.css";
import locale_routes from "../../config/locales/locale.routes";
import { useNavigate } from "react-router-dom";

const PrivacyPageAT = ({ lang }) => {
  const redirect = useNavigate();
  useEffect(() => {
    redirect(`/${locale_routes.privacy_url[lang]}`);
  }, [lang, redirect]);
  return (
    <>
      <section
        className="app__container fade-in app__privacy_page_container"
        id="page-container"
      >
        <div className="wrapper">
          <div className="app__section_title_holder app__title_underline">
            <h1>Datenschutzerklärung</h1>
          </div>
          <div className="app__privacy_page_block">
            <h2>Einführung</h2>
            <p>
              Herzlich willkommen auf unserer Webseite! Der Schutz Ihrer Daten
              ist uns wichtig, und im Folgenden erläutern wir ausführlich, wie
              wir damit umgehen. Bitte lesen Sie dieses Dokument aufmerksam
              durch, um sich über die Datenschutzpraktiken zu informieren.
            </p>
          </div>

          <div className="app__privacy_page_block">
            <h2>Wer sind die Datenverantwortlichen</h2>
            <p>Wir sind die Datenverantwortlichen:</p>
            <ul>
              <li> Name: Péter Horváth ev. (BULIservice@)</li>
              <li> Adresse: 9443, Petőháza, Dózsa György utca 9.</li>
              <li> E-Mail: info@buliservice2000.com </li>
              <li> Steuernummer: 60060567-1-28</li>
              <li> Webseite: http://www.buliservice2000.com/</li>
              <li>
                Erreichbarkeit der Datenschutzerklärung:
                http://www.buliservice2000.com/at/datenschutz
              </li>
            </ul>
          </div>

          <div className="app__privacy_page_block">
            <h2>Welche Daten erfassen wir?</h2>
            <h3>Nutzung der Webseite</h3>
            <p>
              Wenn Sie unsere Webseite besuchen, erfassen wir automatisch
              bestimmte Daten wie Ihre IP-Adresse, den Typ Ihres Browsers, den
              Zeitpunkt des Besuchs und die von Ihnen besuchten Seiten. Diese
              Informationen helfen uns, die Funktionalität unserer Webseite zu
              verbessern und das Nutzererlebnis zu optimieren.
            </p>

            <h3>Google Analytics</h3>
            <p>
              Wir verwenden auch den Google Analytics-Dienst, der anonyme Daten
              über die Aktivitäten unserer Besucher sammelt. Diese Daten helfen
              uns bei der Analyse der Leistung der Webseite und der Verbesserung
              des Nutzererlebnisses. Weitere Informationen zur Datenschutzpraxis
              von Google Analytics finden Sie unter
              <a href="https://policies.google.com/privacy" target="_blank">
                Google Analytics-Datenschutzrichtlinie
              </a>
              .
            </p>

            <h3>Facebook Pixel</h3>
            <p>
              Darüber hinaus verwenden wir auch den Facebook-Pixel auf unserer
              Webseite, der uns dabei hilft, die Effektivität unserer
              Facebook-Anzeigen zu messen und zu verbessern. Die
              Datenschutzrichtlinien des Facebook-Pixels finden Sie auf der
              <a href="https://www.facebook.com/policy" target="_blank">
                Facebook-Datenschutzrichtlinien-Seite
              </a>
              .
            </p>

            <h3>Formulare</h3>
            <p>
              Beim Ausfüllen von Formularen können folgende personenbezogene
              Daten erfasst werden:
            </p>
            <ul>
              <li> Vollständiger Name</li>
              <li> Telefonnummer</li>
              <li> E-Mail-Adresse</li>
            </ul>
            <p>
              Diese Daten sind erforderlich, um auf Ihre Fragen oder Anfragen
              antworten zu können.
            </p>
          </div>

          <div className="app__privacy_page_block">
            <h2>Wie verwenden wir Ihre Daten?</h2>
            <p>
              Die von Ihnen bereitgestellten Daten verwenden wir für folgende
              Zwecke:
            </p>
            <ul>
              <li>
                Gewährleistung und Verbesserung des Betriebs unserer Webseite.
              </li>
              <li>Sammeln und Analysieren von statistischen Informationen.</li>
              <li>
                Kontaktieren Sie für angeforderte Informationen oder
                Dienstleistungen.
              </li>
              <li>
                Analyse und Verbesserung der Wirksamkeit unserer
                Facebook-Anzeigen.
              </li>
            </ul>
          </div>

          <div className="app__privacy_page_block">
            <h2>Datenübertragung</h2>
            <p>
              Die von Ihnen bereitgestellten personenbezogenen Daten werden
              nicht an Dritte weitergegeben, es sei denn, wir sind gesetzlich
              dazu verpflichtet oder Sie haben Ihre ausdrückliche Einwilligung
              erteilt.
            </p>
          </div>

          <div className="app__privacy_page_block">
            <h2>Cookies</h2>
            <p>
              Auf der Webseite verwenden wir Cookies, um die Leistung der
              Webseite zu optimieren und das Nutzererlebnis zu verbessern.
            </p>
          </div>

          <div className="app__privacy_page_block">
            <h2>Hosting-Anbieter</h2>
            <ul>
              <li>Firmenname: DigitalOcean </li>
              <li>Hauptsitz: New York, Vereinigte Staaten</li>
              <li>
                Webseite:{" "}
                <a href="https://www.digitalocean.com" target="_blank">
                  https://www.digitalocean.com/
                </a>
              </li>
            </ul>

            <p>
              Die von Ihnen bereitgestellten Daten werden auf dem von unserem
              Hosting-Anbieter betriebenen Server gespeichert. Nur unsere
              Mitarbeiter und die Mitarbeiter des Anbieters haben Zugriff auf
              diese Daten, und sie haben alle die Verpflichtung zur sicheren
              Verwaltung der Daten übernommen.
            </p>

            <p>
              <strong>Tätigkeit:</strong> Hosting-Dienste, Serverdienste.
            </p>

            <p>
              <strong>Zweck der Datenverarbeitung:</strong> Sicherstellung des
              reibungslosen Betriebs unserer Webseite.
            </p>

            <p>
              <strong>Verarbeitete Daten:</strong> Die von Ihnen
              bereitgestellten personenbezogenen Daten.
            </p>

            <p>
              <strong>
                Dauer der Datenverarbeitung und Frist für die Löschung:
              </strong>{" "}
              Die Datenverarbeitung ist auf die Betriebsdauer der Webseite sowie
              auf die vertraglichen Vereinbarungen zwischen dem Betreiber der
              Webseite und dem Hosting-Anbieter beschränkt. Bei Bedarf können
              Sie auch die Löschung Ihrer Daten vom Hosting-Anbieter verlangen.
            </p>

            <p>
              <strong>Rechtsgrundlage für die Datenverarbeitung:</strong> Die
              Datenverarbeitung erfordert die Einwilligung der betroffenen
              Person sowie kann auch aufgrund gesetzlicher Vorschriften
              erfolgen.
            </p>
          </div>

          <div className="app__privacy_page_block">
            <h2>Haben Sie Fragen?</h2>
            <p>
              Wenn Sie Fragen oder Anmerkungen zum Datenschutz haben,
              kontaktieren Sie uns bitte unter den folgenden Kontaktdaten:{" "}
              <br />
              E-Mail: info@buliservice2000.com
            </p>
          </div>

          <div className="app__privacy_page_block">
            <h2>Aktualisierungen</h2>
            <p>
              Diese Datenschutzerklärung kann von Zeit zu Zeit aktualisiert
              werden. Die neueste Version wird immer auf unserer Webseite
              verfügbar sein.
            </p>
          </div>

          <div className="app__privacy_page_update_block">
            <strong>Budapest, 03.18.2024.</strong>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPageAT;
