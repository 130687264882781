import { useEffect } from "react";
import "./app.privacy.page.css";
import locale_routes from "../../config/locales/locale.routes";
import { useNavigate } from "react-router-dom";

const PrivacyPageHU = ({ lang }) => {
  const redirect = useNavigate();
  useEffect(() => {
    redirect(`/${locale_routes.privacy_url[lang]}`);
  }, [lang, redirect]);
  return (
    <>
      <section
        className="app__container fade-in app__privacy_page_container"
        id="page-container"
      >
        <div className="wrapper">
          <div className="app__section_title_holder app__title_underline">
            <h1>Adatvédelemi tájékoztató</h1>
          </div>
          <div className="app__privacy_page_block">
            <h2>Bevezető</h2>
            <p>
              Szeretettel köszöntünk a weboldalunkon! Adataid védelme
              kulcsfontosságú számunkra, és az alábbiakban részletesen
              bemutatjuk, hogyan kezeljük azokat. Kérjük, olvasd el ezt a
              dokumentumot figyelmesen, hogy tisztában legyél az adataid
              védelmének módjaival.
            </p>
          </div>

          <div className="app__privacy_page_block">
            <h2>Mi vagyunk az adatkezelők</h2>
            <p>Mi vagyunk az adatkezelők:</p>
            <ul>
              <li> Név: Horváth Péter ev. (BULIservice@)</li>
              <li> Cím: 9443, Petőháza, Dózsa György utca 9.</li>
              <li> E-mail: info@buliservice2000.com</li>
              <li> Adószám: 60060567-1-28</li>
              <li> Weboldal címe: http://www.buliservice2000.com</li>
              <li>
                Adatkezelési tájékoztató elérhetősége:
                http://www.buliservice2000.com/adatvedelem
              </li>
            </ul>
          </div>

          <div className="app__privacy_page_block">
            <h2>Milyen adatokat gyűjtünk?</h2>
            <h3>Weboldal használata</h3>
            <p>
              Amikor meglátogatod weboldalunkat, automatikusan gyűjtünk bizonyos
              adatokat, például az IP-címed, a böngésződ típusát, a látogatás
              időpontját és azokat az oldalakat, amiket megnéztél. Ezek az
              információk segítenek nekünk a weboldalunk működésének javításában
              és a felhasználói élmény optimalizálásában.
            </p>

            <h3>Google Analytics</h3>
            <p>
              Weboldalunkon használunk Google Analytics szolgáltatást is, ami
              névtelen adatokat gyűjt a látogatóink tevékenységéről. Ezek az
              adatok segítenek nekünk a weboldal teljesítményének elemzésében és
              a felhasználói élmény javításában. További információkat a Google
              Analytics adatvédelmi gyakorlatáról a{" "}
              <a href="https://policies.google.com/privacy" target="_blank">
                Google Analytics adatvédelmi szabályzat
              </a>{" "}
              címén találsz.
            </p>

            <h3>Facebook Pixel</h3>
            <p>
              Ezenkívül weboldalunkon használunk Facebook Pixelt is, amely segít
              nekünk a Facebook hirdetéseink hatékonyságának mérésében és
              javításában. A Facebook Pixel adatvédelmi irányelveit a{" "}
              <a href="https://www.facebook.com/policy" target="_blank">
                Facebook adatvédelmi szabályzat
              </a>{" "}
              oldalon találod.
            </p>

            <h3>Űrlapok</h3>
            <p>
              Az űrlapok kitöltésekor a következő személyes adatokat
              gyűjthetjük:
            </p>
            <ul>
              <li> Teljes név</li>
              <li> Telefonszám</li>
              <li> E-mail cím</li>
            </ul>
            <p>
              Ezek az adatok szükségesek ahhoz, hogy válaszolhassunk a
              kérdéseidre vagy kéréseidre.
            </p>

            {/* <h3>Hotjar</h3>
            <p>
              A Hotjar egy olyan eszköz, amely segít nekünk megérteni és
              elemzelni a felhasználók viselkedését a webhelyünkön. Ez lehetővé
              teszi számunkra, hogy visszajelzéseket és betekintéseket gyűjtsünk
              a felhasználói élmény javítása érdekében.
            </p>
            <p>A Hotjar különböző adatokat gyűjthet, ideértve:</p>
            <ul>
              <li>
                {" "}
                Eszköz- és böngészőinformációk: Az egyeztetés és a felhasználói
                élmény javítása érdekében.
              </li>
              <li>
                {" "}
                IP-cím: Anonimizált és rövidített a GDPR előírásainak
                megfelelően.
              </li>
              <li>
                {" "}
                Egérmozgások, kattintások és görgetés: A felhasználói viselkedés
                elemzése és a webhely használhatóságának javítása érdekében.
              </li>
              <li>
                Visszajelzések és kérdőívek válaszai: A felhasználók által
                önkéntesen megadott információk.
              </li>
            </ul>
            <p>
              A Hotjar adatait biztonságosan tároljuk, és csak az engedélyezett
              személyzet fér hozzá. Az iparág általános biztonsági
              intézkedéseket alkalmazzuk a Te információidnak védelme érdekében.
            </p> */}
          </div>

          <div className="app__privacy_page_block">
            <h2>Hogyan használjuk az adataidat?</h2>
            <p>Az általad megadott adatokat az alábbi célokra használjuk:</p>
            <ul>
              <li>A weboldalunk működésének biztosítása és fejlesztése.</li>
              <li>Statisztikai információk gyűjtése és elemzése.</li>
              <li>
                Kapcsolatfelvétel veled a kért információk vagy szolgáltatások
                szállításával kapcsolatban.
              </li>
              <li>
                A Facebook hirdetéseink hatékonyságának elemzése és javítása.
              </li>
            </ul>
          </div>

          <div className="app__privacy_page_block">
            <h2>Adattovábbítás</h2>
            <p>
              Az általad megadott személyes adatokat nem adjuk át harmadik
              feleknek, hacsak nincs erre jogi kötelezettségünk vagy a te
              előzetes hozzájárulásod.
            </p>
          </div>

          <div className="app__privacy_page_block">
            <h2>Sütik</h2>
            <p>
              A weboldalon sütiket használunk a weboldal teljesítményének
              optimalizálásához és a felhasználói élmény javításához.
            </p>
          </div>

          <div className="app__privacy_page_block">
            <h2>Tárhelyszolgáltató</h2>
            <ul>
              <li>Cégnév: DigitalOcean </li>
              <li>Székhely: New York, Egyesült Államok</li>
              <li>
                Web:{" "}
                <a href="https://www.digitalocean.com" target="_blank">
                  https://www.digitalocean.com/
                </a>
              </li>
            </ul>

            <p>
              Az általad megadott adatokat a tárhelyszolgáltató által
              üzemeltetett szerveren tároljuk. Csak munkatársaink és a
              szolgáltató munkatársai férhetnek hozzá ezekhez az adatokhoz, és
              mindannyian kötelességet vállaltak az adatok biztonságos
              kezelésére.
            </p>

            <p>
              <strong>Tevékenység:</strong> Tárhelyszolgáltatás,
              szerverszolgáltatás.
            </p>

            <p>
              <strong>Adatkezelés célja:</strong> Weboldalunk zavartalan
              működésének biztosítása.
            </p>

            <p>
              <strong>Kezelt adatok:</strong> Az általad megadott személyes
              adatok.
            </p>

            <p>
              <strong>Adatkezelés időtartama és törlés határideje:</strong> Az
              adatkezelés a weboldal működésének idejére korlátozódik, valamint
              a weboldal üzemeltetője és a tárhelyszolgáltató közötti
              szerződéses megállapodásnak megfelelően. Ha szükségét érzed, az
              adataid törlését is kérheted a tárhelyszolgáltatótól.
            </p>

            <p>
              <strong>Adatkezelés jogalapja:</strong> Az adatkezeléshez az
              érintett személy hozzájárulása szükséges, valamint az adatkezelés
              jogszabályi előírások alapján is történhet.
            </p>
          </div>

          <div className="app__privacy_page_block">
            <h2>Kérdésed van?</h2>
            <p>
              Ha bármilyen kérdésed vagy észrevételed van az adatvédelemmel
              kapcsolatban, kérlek, vedd fel velünk a kapcsolatot az alábbi
              elérhetőségeken: <br />
              E-mail cím: info@buliservice2000.com
            </p>
          </div>

          <div className="app__privacy_page_block">
            <h2>Frissítések</h2>
            <p>
              Ez az adatvédelmi tájékoztató időről időre frissülhet. A
              legfrissebb változat mindig elérhető lesz weboldalunkon.
            </p>
          </div>

          <div className="app__privacy_page_update_block">
            <strong>Budapest, 2024.03.18.</strong>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPageHU;
