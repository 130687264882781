import { createAsyncThunk } from "@reduxjs/toolkit";
import publicAPIServices from "../../services/APIServices";

const API = "/about-us/";

// Action to fetch all data
export const about_us_fetchAllData = createAsyncThunk(
  "aboutUs/get-all-data",
  async () => {
    try {
      const response = await publicAPIServices.fetchData(API + "get-all-data");
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);
