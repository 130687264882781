import React from "react";
import locale_about_us_page from "../../../../config/locales/local.about.us";
import intro_img from "../../../../assets/media/intro_img.jpg";
const Introduction = ({ lang, btn_path }) => {
  const locale = locale_about_us_page;
  return (
    <>
      <div
        className="col-2 d-flex-center"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          padding: "15px",
        }}
      >
        <div>
          <h2>{locale.page_title[lang]}</h2>
        </div>
        <div
          className="homepage_intro"
          dangerouslySetInnerHTML={{
            __html: locale.short_intro[lang],
          }}
        />
        <div className="homepage__intro_btn d-flex-end">
          <a href={btn_path}>{locale.intro_btn[lang]}</a>
        </div>
      </div>
      <div className="col-2 d-flex-end">
        <img
          className="homepage__intro_img shadow"
          src={intro_img}
          alt="Bérelhető légvárak"
          title="Bérelhető légvárak"
        />
      </div>
    </>
  );
};

export default Introduction;
