import { useState, useEffect } from "react";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";
import "./image.modal.css";
const ImageModal = ({
  image,
  closeModal,
  show_nav,
  firstIMG,
  lastIMG,
  prevImg,
  nextIMG,
  publicFolder,
}) => {
  //toch screen / swipe
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe && show_nav && !lastIMG) {
      nextIMG();
    }
    if (isRightSwipe && show_nav && !firstIMG) {
      prevImg();
    }
  };

  // keyboard
  // const handleKeyboard = (event) => {
  //   if (event.key === "ArrowLeft" && show_nav && !firstIMG) {
  //     prevImg();
  //     console.log(event.key);
  //   } else if (event.key === "ArrowRight" && show_nav && !lastIMG) {
  //     nextIMG();
  //     console.log(event.key);
  //   }
  // };

  // useEffect(() => {
  //   const handleKeyDown = (e) => {
  //     handleKeyboard(e);
  //   };

  //   window.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);
  return (
    <div
      className="product_image_modal"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      // onKeyDown={(e) => handleKeyboard(e)}
      // tabIndex={0}
    >
      <div className="close_btn">
        <IoCloseCircle onClick={() => closeModal()} />
      </div>
      {show_nav ? (
        <>
          {!firstIMG ? (
            <div className="image_left_icon_holder">
              <FaArrowCircleLeft onClick={() => prevImg()} />
            </div>
          ) : null}
          {!lastIMG ? (
            <div className="image_right_icon_holder">
              <FaArrowCircleRight onClick={() => nextIMG()} />
            </div>
          ) : null}
        </>
      ) : null}
      <img src={publicFolder + image}></img>
    </div>
  );
};

export default ImageModal;
